import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';

import { withRouter } from "react-router-dom";
import firebase from "firebase";
const db = firebase.firestore();

class ChangePasswordNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      old: '',
      new: '',
    };
  }

  changetitle = (event) => {
    this.setState({old: event.target.value});
  }

  changetitleNew = (event) => {
    this.setState({new: event.target.value});
  }

  doCreatePost = async(e) => {
    e.preventDefault();
      var response = await db.collection("superadmin").where('password', '==', this.state.old).get();
      const data2 = await response.docs.map(doc => doc.id);
      if(data2.length>0){
        const data3 = await db.collection("superadmin").doc(data2[0]).update({
          password: this.state.new
          });
        
            alert('Password Update Successfully');
     
      }else{
        alert('Your old password mismatch');
      }
     
  }

  

  render() {
    return (
      <div>
        <h1>Change Password</h1>
        <Row>
          <Col sm={6}>
            <Widget
              title={
                <span>
                  Change Password
                </span>
              }
            >
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
                <FormGroup>
                  <Label for="input-title">Old Password</Label>
                  <Input
                    id="input-title"
                    type="password"
                    placeholder="Old Password"
                    value={this.state.old}
                    required
                    onChange={this.changetitle}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-datefrom">New Password</Label>
                  <Input
                    id="input-datefrom"
                    type="password"
                    placeholder="New Password"
                    value={this.state.new}
                    onChange={this.changetitleNew}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="submit">
                      {this.props.isFetching ? 'updating...' : 'Update'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(ChangePasswordNew);
