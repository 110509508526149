import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";

// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

const db = firebase.firestore();

class DriversList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [],Charges:0 };
  }
  componentDidMount(){
    db.collection('Drivers')
            .get()
            .then( snapshot => {
                const dataSet = []
                snapshot.forEach(doc => {
                    dataSet.push([doc.data().name, doc.data().email, doc.data().phone,doc.id,doc.data().email,[doc.id,doc.data().active_status],doc.id]);
                });
                console.log("dataSet",dataSet);
                  return dataSet;
              })
             .then(userList => { 
              this.setState({dataSet:userList});
            })
        .catch(error => console.log(error));
        db.collection("delivery_Charges").doc('Ufood_deliver_charges_0001').get().then((docRef) => { 
          var data = docRef.data();
          this.setState({
              Charges: data.Charges,
              });
           }).catch(error => console.log(error));
  }
  customerdelete(id) {
      if (window.confirm("Delete the item?")) {
      db.collection("Drivers").doc(id).delete();
      this.componentDidMount();
    }
    }

    sendmail(email){
      console.log('email',email);
      firebase.auth().sendPasswordResetEmail(email).then((res) => {
          alert('Rest password email sent on '+email);
      }).catch((error) => {
        console.log('errors',error);
        alert(error.message);
      });
    }

    updateStatus(id,status)
    {
      if (window.confirm("Are You Sure?")) {
        let statusVal="true";
        if(status=="true")
        {
          statusVal="false";
        }
        firebase.firestore().collection("Drivers").doc(id).update({
        active_status: statusVal
        });
        this.componentDidMount();
      }
    }

  render() {
  return (
    <>
      <PageTitle title="Drivers" />
      <h6 class="deliveryCharges"><b>Delivery Charges:</b> {this.state.Charges}</h6>
      <div className="pull-right mt-n-xs">
      <Link to="/app/drivers/charges" className="btn btn-primary btn-inverse updateDc" >
                  Update delivery Charges
                </Link>
                <Link to="/app/drivers/new" className="btn btn-primary btn-inverse">
                  Create New Drivers
                </Link>
              </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={this.state.dataSet}
            columns={["Name", "Email", "Phone", {
              label: "Edit",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link to={"/app/drivers/edit/" + value} className='btn btn-primary'> Edit </Link>
                      )
                  }
              }
          },
          {
            label: "Rest Password",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <button className='btn btn-success'   onClick={() =>this.sendmail(value)} > Send Mail </button>
                    )
                }
            }
            },{
              label: "Action",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                      <div>
                    {(() => {
                    if (value[1]=="true") {
                      return (<button className='btn btn-danger' onClick={() => this.updateStatus(value[0],value[1])}>Disable</button>)
                    }else{
                      return (<button className='btn btn-success' onClick={() => this.updateStatus(value[0],value[1])}>Enable</button>)
                    }
                    })()}
                     </div>)
                  }
              }
          },
          {
              label: "Delete",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                      <button onClick={() => this.customerdelete(value)}>Delete</button>
                      )
                  }
              }
          }]}
            options={{
              filterType: "checkbox",
            }}
          />
        </Grid>
        {/*<Grid item xs={12}>
          <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <Table data={mock.table} />
          </Widget>
        </Grid>*/}
      </Grid>
    </>
  );
}
}
export default withRouter(DriversList);


