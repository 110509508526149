import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";
import $ from "jquery";
// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import MaterialTable from "material-table";
class RestaurantList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [], columns:[
      { title: 'Dish Name', field: 'dish_name' },
      { title: 'Image', field: 'image'},
      { title: 'Price', field: 'price' },
      { title:  'Enable/Disable', field: 'edit'}
    ],dataSet2:[] };
  }


  componentDidMount(){

  	firebase.firestore().collection('restaurants')
            .get()
            .then( snapshot => {
                const dataSet = []
                snapshot.forEach(doc => {
                dataSet.push([doc.data().name, doc.data().restaurant_email, doc.data().address, doc.id, doc.id, doc.data().email, doc.id, [doc.id,doc.data().status]]);
      
                });
                  return dataSet;
              })
             .then(userList => { 
             	this.setState({dataSet:userList});
            })
        .catch(error => console.log(error))


  }
 

restaurantdelete(id) {
      if (window.confirm("Delete the item?")) {
        firebase.firestore().collection("restaurants").doc(id).delete();
        var body={ids:id};
        axios.post('https://rapidmalta.com/APIS/admin/deleteRestufood',body, {
        headers: {
        Accept: 'multipart/form-data',
        "Content-Type": 'multipart/form-data',
        },
        });
        var jobskill_query = firebase.firestore().collection('menus').where('resturent_id','==',id);
jobskill_query.get().then(function(querySnapshot) {
  querySnapshot.forEach(function(doc) {
    doc.ref.delete();
  });
})
  var jobskill_query2 = firebase.firestore().collection('Favourites').where('resturent_id','==',id);
  jobskill_query2.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.delete();
    });
});
      this.componentDidMount();
    }
    }
    restaurantdisable(id,v,h) {
      if (window.confirm("Are You Sure?")) {
       firebase.firestore().collection("restaurants").doc(id).update({
       status: v
       });
       if(v==0)
       {
        var datas=2;
       }
       else
       {
         var datas=1;
       }
       var body={status:datas,uid:id};
        axios.post('https://rapidmalta.com/APIS/admin/updateRestuStatusfood',body, {
        headers: {
        Accept: 'multipart/form-data',
        "Content-Type": 'multipart/form-data',
        },
        })
        .then((res) => {
          this.componentDidMount();
        });
  
    }
    }
    sendmail(e)
    {
      var email=e.currentTarget.id;
      var actionCodeSettings = {
        url: 'https://vendorapp-aa075.web.app?home=true',
        handleCodeInApp: false
      };
      firebase.auth().sendPasswordResetEmail(e.currentTarget.id,actionCodeSettings).then((res) => {
     alert('Email send Successfully on '+email);
      }).catch((error) => {
    console.log('err',error);
      });
  
    }

    openpop=async(id)=>
    {
      var response =  await firebase.firestore().collection("menus").where('resturent_id', '==', id).get();
      const data = await response.docs.map(doc => doc.data());
      const data2 = await response.docs.map(doc => doc.id);
      var ingredient={'list':data,'ids':data2}
      console.log("ingredient",ingredient);
      if(ingredient.list.length>0)
      {
      var title=[]
      for (var i = 0; i < ingredient.list.length; i++) {
      title[i] = {
      dish_name:ingredient.list[i].dish_name,
      image: <img src={ingredient.list[i].featured_image} style={{ width: 40, borderRadius: '50%' }} />,
      price:ingredient.list[i].price,
      edit:<button className={ingredient.list[i].status && ingredient.list[i].status=="block" ? 'btn btn-success' : 'btn btn-danger' } id={ingredient.ids[i]} onClick={this.changebutton.bind(this)}>{ingredient.list[i].status && ingredient.list[i].status=="block" ? 'Enable' : 'Disble' }</button>,
      key:ingredient.ids[i]

      };
      }
      this.setState({dataSet2:title});
      }
    $('#paypal').removeClass('blockend2');
    }
    closepop()
    {
      $('#paypal').addClass('blockend2');
      this.setState({dataSet2:[]})
    }

  render() {
    return(
      <>
      <PageTitle title="Restaurants" />
      <div className="pull-right mt-n-xs">
                <Link to="/app/restaurants/new" className="btn btn-primary btn-inverse">
                  Create New Restaurants
                </Link>
              </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={this.state.dataSet}
            columns={["Restaurants", "Email", "Address", {
              label: "Menulist",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link onClick={this.openpop.bind(this,value)}  className='btn btn-primary'> Menu </Link>
                      )
                  }
              }
              }, {
              label: "Edit",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link to={"/app/restaurants/edit/" + value} className='btn btn-primary'> Edit </Link>
                      )
                  }
              }
              },
              {
                label: "Rest Password",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <button className='btn btn-success'  id={value} onClick={this.sendmail.bind(this)} > Send Mail </button>
                        )
                    }
                }
                },
              
              {
              label: "Delete",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                      <button className='btn btn-danger' onClick={() => this.restaurantdelete(value)}>Delete</button>
                      )
                  }
              }
          }, {
              label: "Action",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                      <div>
                      {(() => {
            if (value[1]==1) {
              
              return (
              <button className='btn btn-danger' onClick={() => this.restaurantdisable(value[0],0,value[1])}>Disable</button>)
                    }else{
                     return (<button className='btn btn-success' onClick={() => this.restaurantdisable(value[0],1,value[1])}>Enable</button>)
                  }
                  })()}
                     </div>)
                  }
              }
          }
          ]}
           
          />
        </Grid>
      </Grid>
      <div  id="paypal" class="blockend2">
      <div class="uupdiv">
      <i class="fa fa-times" aria-hidden="true" onClick={this.closepop.bind(this)}></i>

      
      <MaterialTable
            data={this.state.dataSet2}
            columns={this.state.columns}
            title={"Menus"}
          />
         </div>
    </div>
    </>
  );
  }
}
export default withRouter(RestaurantList);


