import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";

// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
const db = firebase.firestore();

class MenusList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [],columns:[
      { title: 'Dish Name', field: 'dish_name' },
      { title: 'Image', field: 'image'},
      { title: 'Price', field: 'price' },
      { title:  'Enable/Disable', field: 'edit'}
    ] }
  
  }
  changebutton(e)
  {
if(e.target.className=="btn btn-danger")
{

  var objectIndex=this.state.dataSet.findIndex((obj => obj.key == e.currentTarget.id));
  if(objectIndex!==-1)
  {
    this.state.dataSet[objectIndex].edit=<button className="btn btn-success" id={e.currentTarget.id} onClick={this.changebutton.bind(this)}>Enable</button>;
    this.setState({dataSet:this.state.dataSet});
  }
   db.collection('menus').doc(e.currentTarget.id).update({status:"block"}); 
}
if(e.target.className=="btn btn-success")
{
  var objectIndex=this.state.dataSet.findIndex((obj => obj.key == e.currentTarget.id));
  if(objectIndex!==-1)
  {
    this.state.dataSet[objectIndex].edit=<button className="btn btn-danger" id={e.currentTarget.id} onClick={this.changebutton.bind(this)}>Disable</button>;
    this.setState({dataSet:this.state.dataSet});
}
db.collection('menus').doc(e.currentTarget.id).update({status:"active"}); 
  }
}

  componentDidMount=async()=>{


    var response =  await db.collection("menus").where('resturent_id', '==', this.props.match.params.id).get();
            const data = await response.docs.map(doc => doc.data());
            const data2 = await response.docs.map(doc => doc.id);
            var ingredient={'list':data,'ids':data2}
            console.log("ingredient",ingredient);
            if(ingredient.list.length>0)
            {
            var title=[]
            for (var i = 0; i < ingredient.list.length; i++) {
            title[i] = {
            dish_name:ingredient.list[i].dish_name,
            image: <img src={ingredient.list[i].featured_image} style={{ width: 40, borderRadius: '50%' }} />,
            price:ingredient.list[i].price,
            edit:<button className={ingredient.list[i].status && ingredient.list[i].status=="block" ? 'btn btn-success' : 'btn btn-danger' } id={ingredient.ids[i]} onClick={this.changebutton.bind(this)}>{ingredient.list[i].status && ingredient.list[i].status=="block" ? 'Enable' : 'Disble' }</button>,
            key:ingredient.ids[i]
           
            };
            }
            this.setState({dataSet:title});
            }
  }
 


  render() {
    return(
      <>
      <PageTitle title="Menus" />
      <i class="fa fa-arrow-left romoveback"></i>
      <div className="pull-right mt-n-xs">
                
              </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MaterialTable
            title=""
            data={this.state.dataSet}
            columns={this.state.columns}
            title="Menus"
          />
        </Grid>
      </Grid>
    </>
  );
  }
}



export default withRouter(MenusList);


