import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  CustomInput,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Geocode from "react-geocode";
const db = firebase.firestore();
class contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      phone:"",
      loadings:false,lat:"",long:"",address:""
    };
   
  }
  componentDidMount()
  {
    db.collection("contact").doc("bHkLg4huDqqP9ulyyixFContact").get().then((docRef) => { 
      if(docRef.data())
      {
      var data = docRef.data();
      this.setState({
          email:data.email,
          phone:data.phone,
          address:data.address,
          lat:data.lat,
          long:data.long,
            loadings:false});
       
      }
    })
       window.initMap = this.initMap
       const gmapScriptEl = document.createElement(`script`)
       gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk&libraries=places&callback=initMap`;
       // https://maps.googleapis.com/maps/api/js?key=SECRET_EATING&libraries=places&callback=initMap
       document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
     }
   
     initMap = () => {
       this.setState({
         gmapsLoaded: true,
       })
     }
  changeemail = (event) => {
    this.setState({email: event.target.value});
  }
  changephone = (event) => {
    this.setState({phone: event.target.value});
  }

  submitform = async() => {
if(this.state.loadings==false)
{
    if(this.state.email=="" || this.state.phone=="")
     {
       alert("Please fill all fields");
     }
     else{
    this.setState({loadings:true},()=>
    {
      db.collection("contact").doc("bHkLg4huDqqP9ulyyixFContact").set({email:this.state.email,phone:this.state.phone,address:this.state.address,lat:this.state.lat,long:this.state.long});
  this.componentDidMount();
    });
      
        }
      }
    }
    handleChange = address => {
      this.setState({ address });
    };
   
    handleSelect = address => {
      this.setState({ address });
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          this.setState({lat:latLng.lat,long:latLng.lng});
          this.fetchAddress(latLng.lat,latLng.lng);
        })
        .catch(error => console.error('Error', error));
    };
  
    fetchAddress(lati,lngi)
         {
           Geocode.setApiKey("AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk");
           Geocode.setLanguage("en");
           Geocode.setRegion("es");
           Geocode.setLocationType("ROOFTOP");
           Geocode.enableDebug();
           Geocode.fromLatLng(lati,lngi).then(
              (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country;
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                  for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                    switch (response.results[0].address_components[i].types[j]) {
                      case "locality":
                        city = response.results[0].address_components[i].long_name;
                        break;
                      case "administrative_area_level_1":
                        state = response.results[0].address_components[i].long_name;
                        break;
                      case "country":
                        country = response.results[0].address_components[i].long_name;
                        break;
                    }
                  }
                }
                this.setState({address: city+", "+state+", "+country,city:city});
              },
              (error) => {
                console.error(error);
              }
            );
            
  
              // this.getDocumentNearBy(lati, lngi, 50);
        
         }
   

  render() {
    return (
      <div>
        <h1>Update Contact Us Page</h1>
        <Row>
          <Col sm={12}>
            <Widget
       
            >
              <div>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
                
                <FormGroup>
                  <Label for="input-datefrom">Email</Label>
                  <Input
                    id="input-datefrom"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.changeemail.bind(this)}
                   
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-phone_no">Phone No</Label>
                  <Input
                    id="input-phone_no"
                    type="number"
                    placeholder="Phone No"
                    value={this.state.phone}
                    onChange={this.changephone.bind(this)}
                  />
                </FormGroup>
  
  
                <FormGroup>
                  <Label for="input-autocomplete">Address</Label>
                  {this.state.gmapsLoaded && (<PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange.bind(this)}
        onSelect={this.handleSelect.bind(this)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>

          
        )}
      </PlacesAutocomplete>)}
           
                </FormGroup>
         

    
                                    
               
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="button" onClick={this.submitform.bind(this)}>
                      {this.state.loadings==true ? 'updateing...' : 'Update'}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(contact);
