import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import ChangePasswordNew from './new/ChangePasswordNew';
class ChangePassword extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/ChangePassword" exact component={ChangePasswordNew} />
      </Switch>
    );
  }
}

export default withRouter(ChangePassword);
