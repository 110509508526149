import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { withRouter } from "react-router-dom";
import firebase from "firebase";

const db = firebase.firestore();
const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

class DriversNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new Driver',
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      code:'',
    };
  }

  changefname = (event) => {
    this.setState({first_name: event.target.value});
  }
  // changelname = (event) => {
  //   this.setState({last_name: event.target.value});
  // }
  changeemail = (event) => {
    this.setState({email: event.target.value});
  }
  changephone = (event) => {
    if (rx_live.test(event.target.value)){
      this.setState({phone: event.target.value});
      }
  }
  changecode=(val)=>{
    this.setState({code: val});
  }
  
  doCreatePost = async(e) => {
    e.preventDefault();
    //   var actionCodeSettings = {
    //   url: 'https://vendorapp-aa075.web.app?home=true',
    //   handleCodeInApp: false
    // };
    try{
    let password=Math.floor(100000 + Math.random() * 900000);
    const authusers = await firebase.auth().createUserWithEmailAndPassword(this.state.email,password.toString());
    if(authusers.user.uid)
    {
      const userRef = await db.collection('Drivers').doc(authusers.user.uid).set({
      name: this.state.first_name,
      email:this.state.email,
      code:this.state.code,
      phone: this.state.phone,
      image:"https://picsum.photos/seed/picsum/200/300",
      active_status:"true",
      });
      firebase.auth().sendPasswordResetEmail(this.state.email).then((res) => {
      }).catch((error) => {
        alert(error.message);
      });
      this.props.history.push('/app/drivers');
    }
    else{
      alert('somthing went wrong');
    }
    }catch(e){
      alert(e.message);
    }
  }



  render() {
    return (
      <div>
        <h1>Create new Driver</h1>
        <Row>
          <Col sm={6}>
            <Widget
              title={
                <span>
                  Add Driver
                </span>
              }
            >
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
                <FormGroup>
                  <Label for="input-fname">Name</Label>
                  <Input
                    id="input-fname"
                    type="text"
                    placeholder="First Name"
                    value={this.state.first_name}
                    required
                    onChange={this.changefname}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-email">Email</Label>
                  <Input
                    id="input-email"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.changeemail}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-email">Country code</Label>
                  <PhoneInput
                  country={"eg"}
                  enableSearch={true}
                  value={this.state.code}
                  onChange={this.changecode}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-phone">Phone</Label>
                  <Input
                    id="input-phone"
                    type="text"
                    placeholder="Phone"
                    value={this.state.phone}
                    onChange={this.changephone}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="submit">
                      {this.props.isFetching ? 'Creating...' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(DriversNew);
