import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import DriversList from './list/DriversList';
import DriversNew from './new/DriversNew';
import DriversEdit from './edit/DriversEdit';
import DriversCharges from './charges/DriversCharges';
class Drivers extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/drivers" exact component={DriversList} />
        <Route path="/app/drivers/new" exact component={DriversNew} />
        <Route path="/app/drivers/charges" exact component={DriversCharges} />
        <Route path="/app/drivers/edit/:id" exact component={DriversEdit} />
      </Switch>
    );
  }
}

export default withRouter(Drivers);
